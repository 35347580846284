import React from 'react';
import styled from 'styled-components';

const FourOhFourStyles = styled.div`
  height: calc(100vh - 22rem);
  text-align: center;
  h2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const FourOhFourPage = () => (
  <FourOhFourStyles>
    <h2 className="h--medium">Sorry, you reached a page that doesn't exist.</h2>
  </FourOhFourStyles>
);
export default FourOhFourPage;
